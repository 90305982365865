import React from "react";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import "./styles/Home.css";
import {useNFTMint} from "./hooks/useNFTmint";

export default function Home() {
	const address = useAddress();
	const { didUserMint } = useNFTMint(address || "")
	const finalText = "Check+out+this+new+ham+widget+for+iOS%21%0D%0A%0D%0AFollow+%40leovido+for+more+web3+widgets+and+frames"

	const copyScript = async () => {
		try {
			const fid = await fetch("https://api.neynar.com/v2/farcaster/user/bulk-by-address?" + new URLSearchParams({
				addresses: address,
				api_key: process.env.NEYNAR_API_KEY
			}))
			
			const response = await fetch("/scripts/ham-widget.js");
			if (!response.ok) {
				throw new Error("Failed to fetch script");
			}
			const scriptCode = await response.text();
            
			// Create a temporary textarea to copy the script content
			const textField = document.createElement("textarea");
			textField.value = scriptCode;
			scriptCode.replace("", fid)
			document.body.appendChild(textField);
			textField.select();
			document.execCommand("copy");
			document.body.removeChild(textField);
		} catch (error) {
			console.error("Error fetching or copying script:", error);
			alert("Failed to fetch or copy script.");
		}
	};

	return (
		<main className="main">
			<div className="container">
				<div className="header">
					<h1 className="title">
						<span className="gradient-text-0">
              Ham widget (iOS)
						</span>
					</h1>

					<div className="container">
						<h2 style={{fontWeight: 500}}>1. Connect your wallet</h2>
						<div className="connect">
							<ConnectWallet />
						</div>
						<p style={{fontSize: 18, textAlign: "justify"}}>Connecting is safe as it uses Third Web SDK. 
						No transaction will occurr, only signing to fetch your public address</p>  

						<p style={{fontSize: 18, textAlign: "justify"}}>Make sure to choose the wallet that received the airdrop if you were early by interacting with  
							<a
								style={{marginLeft: "4px"}}
								href="https://www.warpcast.com/leovido.eth"
								target="https://www.warpcast.com/leovido.eth"
								rel="noopener noreferrer"
							>
              le frame in /lp
							</a>
						</p>
					</div>

					<div className="container">

						<h2 style={{textAlign: "justify", fontWeight: 500}}>2. Check eligibility</h2>
					
						{address === undefined && <div>
							<p>Connect your wallet first</p>
						</div>}

						{didUserMint && 
						<div>
							<p>You are early. Congratulations! ✅</p>
							<img
								src="/images/the-widget.jpg"
								alt="Screenshot of widget displays"
								style={{borderRadius: 10, width: "40vw"}}
							/>
							<h2 style={{textAlign: "justify", fontWeight: 500}}>3. Install. </h2>
							<p>If you have installed 
								<a
									style={{marginLeft: "4px", marginRight: "4px"}}
									href="https://warpcast.com/iamng"
									target="https://warpcast.com/iamng"
									rel="noopener noreferrer"
								>
                @iamng&apos;s 
								</a>
						$DEGEN widgets, you should be familiarised with installing widgets with Scriptable
							</p>
							<p>
								Click <a
									style={{marginLeft: "4px", marginRight: "4px"}}
									href="https://iamng.wtf/widgets/fc-degen/points"
									target="https://iamng.wtf/widgets/fc-degen/points"
									rel="noopener noreferrer"
								>
                here
								</a> if you need a refresher
							</p>

							<button onClick={copyScript}>Copy code</button>
							
						</div>
						}
					
						{
							!didUserMint && <div>
								<h2 style={{textAlign: "justify", fontWeight: 500}}>3. Mint your NFT 
									<a
										style={{marginLeft: "4px"}}
										href="https://zora.co/collect/zora:0x9b40e25de3bd7647b2560d459cab2f70d4983c06/1"
										target="https://zora.co/collect/zora:0x9b40e25de3bd7647b2560d459cab2f70d4983c06/1"
										rel="noopener noreferrer"
									>
              here
									</a>	
								</h2>
								<p>If you were early and interacted with le frame, you should have an NFT available in your selected wallet</p>
						
							</div>
						}
						
						<h2 style={{textAlign: "justify", fontWeight: 500}}>4. Any questions? DM me on Warpcast 
							<a
								style={{marginLeft: "4px"}}
								href="https://www.warpcast.com/leovido.eth"
								target="https://www.warpcast.com/leovido.eth"
								rel="noopener noreferrer"
							>
              @leovido
							</a>	
						</h2>
						<h2 style={{textAlign: "justify", fontWeight: 500}}>5. Share the love and your screenshot of your beautiful widget. Show off your juicy ham points!
							<a
								style={{marginLeft: "4px"}}
								href={finalText}
								target={finalText}
								rel="noopener noreferrer"
							>
              Share now on Warpcast
							</a>	
						</h2>
						<img style={{borderRadius: 10, alignSelf: "center"}} src="/images/iphone-screenshot.png" width="700vw" />
						
					</div>
				</div>
			</div>
		</main>
	);
}
