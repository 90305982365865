"use client";
import { zdk } from "../zora";
import { MintsQueryArgs, ZDKChain, ZDKNetwork } from "@zoralabs/zdk";
import { useEffect, useMemo, useState } from "react";

export const useNFTMint = (userAddress: string) => {
	const [didUserMint, setDidUserMint] = useState(false)
	const [allAddresses, setAllAddreses] = useState([""])
	const args: MintsQueryArgs = useMemo(() => {
		return {
			where: {
				tokens: [
					{
						address: "0x9b40e25de3bd7647b2560d459cab2f70d4983c06",
						tokenId: "1"
					}
				]
			},
			networks: [
				{
					network: ZDKNetwork.Zora,
					chain: ZDKChain.ZoraMainnet
				}
			],
			includeFullDetails: false
		}
	}, [])

	useEffect(() => {
		async function process() {
			try {
				const response = await zdk.mints(args)

				const addresses = response.mints.nodes.flatMap((node) => {
					return node.mint.toAddress
				});

				setAllAddreses(addresses)
				setDidUserMint(addresses[0].toLowerCase() === userAddress.toLowerCase())
			} catch (e) {
				console.log(e)
			}
		}
		process()
	}, [args, userAddress])

	return {
		didUserMint,
		allAddresses
	}
}