import { ZDK, ZDKNetwork, ZDKChain } from "@zoralabs/zdk";

const networkInfo = {
	network: ZDKNetwork.Base,
	chain: ZDKChain.BaseMainnet,
}

const API_ENDPOINT = "https://api.zora.co/graphql";
const args = {
	endPoint: API_ENDPOINT,
	networks: [networkInfo],
	apiKey: process.env.ZDK_API_KEY || ""
}

export const zdk = new ZDK(args)
